<template lang="pug">
div
  div(v-if="!organizers")
    loading-spinner(message="Loading organisers...")

  div.container(v-if="organizers")
    div.row.justify-content-center
      h1 Manage Event Organizers
    div.row.justify-content-center.text-center
      p: i Note: Changing an organizers' name doesn't automatically update it in past events! Do a find & replace in the DB if needed.

    br/
    br/
    div.row.justify-content-center
      div.col-lg-6
        table.table.table-bordered
          thead.thead-dark
            tr
              th Organizer
          tbody
            tr(v-for="organizer in organizers" :key="organizer._id")
              td
                b-form.justify-content-center(inline)
                  b-input(id="organizerName" v-model="organizer.name")/
                  b-button.m-2(variant="info" size="sm" @click="updateOrganizer(organizer._id, organizer)") Update
                  b-button(variant="danger" size="sm" @click="deleteOrganizer(organizer._id)") Delete

    br/
    br/
    div.row.justify-content-center
      div.col-lg-6
        h3 Add New Organizer
        b-form(inline)
          b-form-group(label="Organizer Name" label-for="name")
            b-form-input(name="name" type="text" v-model="newOrganizer.name" required placeholder="Uusikaupunki")
          b-button(variant="success" @click="createOrganizer(newOrganizer)") Add
</template>

<script>
export default {
  data: function () {
    return {
      organizers: null,
      newOrganizer: {
        name: null
      }
    }
  },
  methods: {
    getOrganizers: function () {
      this.axios.get('/api/events/organizers')
        .then(res => (this.organizers = res.data))
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching organizers failed',
          text: err.response.data,
          duration: -1
        })))
    },
    createOrganizer: function (newOrganizer) {
      this.axios.post('/api/events/organizers/add', newOrganizer)
        .then(res => {
          this.getOrganizers()

          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Organizer added',
            text: newOrganizer.name + ' is now a thing!'
          })

          this.newOrganizer.name = null
        })
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Adding organizer failed',
          text: err.response.data,
          duration: -1
        })))
    },
    updateOrganizer: function (id, updatedOrganizer) {
      this.axios.put('/api/events/organizers/' + id, updatedOrganizer)
        .then(res => {
          this.getOrganizers()

          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Organizer updated',
            text: updatedOrganizer.name + ' is now up-to-date!'
          })
        })
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Updating organizer failed',
          text: err.response.data,
          duration: -1
        })))
    },
    deleteOrganizer: function (id) {
      this.axios.delete('/api/events/organizers/' + id)
        .then(res => {
          this.getOrganizers()

          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Organizer deleted'
          })
        })
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Deleting organizer failed',
          text: err.response.data,
          duration: -1
        })))
    }
  },
  mounted: function () {
    this.getOrganizers()
  }
}
</script>
